import React from 'react';
import { Container } from './styled';
import Heading from './Heading';
import Post from './Post';

function BlogPost(pageData) {
  return (
    <Container>
      <Heading />
      <Post {...pageData}/>
    </Container>
  );
}

export default BlogPost;
