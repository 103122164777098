import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  background: #F4F5FA;
  > img {
    width: 100%;
    height: 62.50vw;
    object-fit: contain;
  }
`;

export const Body = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
`;

export const Badge = styled.div`
  height: 1.500vw;
  width: 8.000vw;
  background: #FE95A2;
  border-radius: 4px;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.7500vw;
  color: #FFFFFF;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 1.688vw;
`;

export const Title = styled.pre`
  margin: 0;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 3.875vw;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 72px;
  margin-bottom: 4vw;
`;

export const Info = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 1.125vw;
  color: #FFFFFF;
  line-height: 28px;
  width: 41.25vw;
  opacity: 0.5;
`;

export const Button = styled.div`
  background: #FFFFFF;
  border-radius: 30px;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.8750vw;
  color: #222328;
  letter-spacing: 1.5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  height: 3.750vw;
  width: 15.06vw;
  margin-top: 4.375vw;
  cursor: pointer;
`;