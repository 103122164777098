import React from 'react';
import {
  Container, Body, Badge, Title, Info, Button,
} from './styled';
import Background from './Background.png';

function Heading() {
  return (
    <Container>
      <img src={Background} />
      <Body>
        <Badge>Read this First</Badge>
        <Title>{`The So Bridely Story:
Why we started this company.`}</Title>
        <Info>Whether you need simple pages, striking galleries, a professional blog, or an online store, it's all included with your Squarespace website. Best of all, everything is mobile-ready right from the start.</Info>
        <Button>Start Reading</Button>
      </Body>
    </Container>
  );
}

export default Heading;
