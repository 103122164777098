import React from 'react';
import {
  Container, Previous, Next, PageNumbers, Number,
} from './styled';
import LeftArrow from './LeftArrow.svg';
import RightArrow from './RightArrow.svg';

const max = 4;

function getNumbers(currentPage, pageMax) {
  const left = [];
  const middle = Array(max).fill(0).map((_, index) => {
    const middleNum = (index + 1) + (parseInt((currentPage - 1) / max) * max);

    if (middleNum <= pageMax) {
      return middleNum;
    }
  });
  const right = [];

  if (middle[0] + max < pageMax) {
    right.push('...');
    right.push(pageMax);
  }
  return [...left, ...middle, ...right];
}

function getPostRangeForPage(page) {
  return (page - 1) * 6;
}

function PageSelector({
  setPostIndex, currentPage, setPage, pageMax,
}) {
  const numbersToShow = getNumbers(currentPage, pageMax);
  return (
    <Container>
      <Previous onClick={() => {
        if (currentPage > 1) {
          setPage(currentPage - 1);
          setPostIndex(getPostRangeForPage(currentPage - 1));
        }
      }}>
        <img src={LeftArrow} />
        PREV
      </Previous>
      <PageNumbers>
        {numbersToShow.map((number, index) => (
          <Number
            onClick={() => {
              setPage(number);
              setPostIndex(getPostRangeForPage(number));
            }}
            active={currentPage == number}
            key={index}>
            {number}
          </Number>
        ))}
      </PageNumbers>
      <Next
        onClick={() => {
          if (currentPage < pageMax) {
            setPage(currentPage + 1);
            setPostIndex(getPostRangeForPage(currentPage + 1));
          }
        }}>
        NEXT
        <img src={RightArrow} />
      </Next>
    </Container>
  );
}

export default PageSelector;
