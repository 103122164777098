import React from 'react';
import { graphql } from 'gatsby';
import { navigate } from '@reach/router';
//components
import Layout from '../components/Layout';
import BlogDesktop from '../components/Desktop/Blog';
import BlogPostDesktop from '../components/Desktop/BlogPost';
//helpers
//styles
import './style.css';
import { getPageIdFromUrl } from '../helpers/Fetch';
import { formatPostData } from '../helpers/Wordpress';

class Blog extends React.Component {
  render() {
    let pageId;
    let pageData;

    if (typeof window !== 'undefined' && window.location) {
      const matches = /blog\/(.*)/gi.exec(window.location.pathname) || [];
      pageId = matches[1];
    }

    const allpageData = formatPostData(this.props.data);

    if (pageId) {
      pageData = allpageData.find(
        ({ guid }) => getPageIdFromUrl(guid) === pageId,
      );
    }

    if (pageId && !pageData && typeof window !== 'undefined') {
      navigate('/404');
      return <Layout />;
    }

    const component = pageId ? (
      <BlogPostDesktop {...pageData} />
    ) : (
      <BlogDesktop posts={allpageData} />
    );
    return <Layout id={pageId} mobile={component} desktop={component} />;
  }
}

export default Blog;

export const query = graphql`
  query {
    post_data: allWordpressPost {
      edges {
        node {
          acf {
            author
            show_heading
          }
          title
          content
          guid
          categories {
            name
          }
          date
        }
      }
    }
  }
`;
