import React, { useState } from 'react';
import moment from 'moment';
import { navigate } from '@reach/router';
import { getPageIdFromUrl } from '../../../../helpers/Fetch';
import {
  Container, Posts, Post, Category, Title, Summary, Footer, Name, Date, Image,
} from './styled';
import PageSelector from './PageSelector';
import SideBar from './SideBar';

function getFirstImageFromContent(html) {
  const matches = /img.*?src="(.*?)"/ig.exec(html) || [];
  return matches[1] || null;
}

const htmlDecode = innerHTML => Object.assign(document.createElement('textarea'), { innerHTML }).value;


function getSummaryPreview(html) {
  let text = '';
  const paragraphMatches = /<p>(.*?)<\/p>/ig.exec(html) || [];
  const headerMatches = /<h\d*>(.*?)<\/h\d*>/ig.exec(html) || [];

  if (headerMatches.length) {
    text = headerMatches[1];
  } else if (paragraphMatches.length) {
    text = paragraphMatches[1];
  }
  return text.substr(0, 200);
}

//.sort((a, b) => b.date - a.date);
function Body({ posts = [] }) {
  const [postsIndexToShow, setPostsIndexToShow] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const postsToShow = posts.slice(0)
    .splice(postsIndexToShow, 6);
  return (
    <Container>
      <div>
        <Posts>
          {postsToShow.map(({
            categories, acf: { author = '' }, content, date, title, guid,
          }, index) => {
            const dateFormatted = moment(date).format('D MMM YYYY');
            const previewImage = getFirstImageFromContent(content);
            const summaryPreview = getSummaryPreview(content);
            const pageId = getPageIdFromUrl(guid);
            return (
              <Post onClick={() => navigate(`/blog/${pageId}`)} key={index}>
                {categories && categories[0] && <Category>{categories[0].name}</Category>}
                <Image>{previewImage && <img src={previewImage} />}</Image>
                <Title>{htmlDecode(title)}</Title>
                <Summary>{summaryPreview}</Summary>
                <Footer>
                  {author && <Name>By {author} /</Name>}
                  <Date>&nbsp;{dateFormatted}</Date>
                </Footer>
              </Post>
            );
          })}
        </Posts>
        <PageSelector
          pageMax={parseInt(posts.length / 6)}
          setPostIndex={setPostsIndexToShow}
          setPage={setCurrentPage}
          currentPage={currentPage} />
      </div>
      <SideBar />
    </Container>
  );
}

export default Body;
