import { DataSelector } from '../../selectors/index';

export const formatHomeData = (data) => DataSelector(data).getAllDataByType('home_data', 'acf');
export const formatNavigationMenuData = (data) => DataSelector(data).getAllDataByType('navigation_menu_data', 'acf');
export const formatNavigationMenuLinksData = (data) => DataSelector(data).getAllDataByType('navigation_menu_links', 'items');
export const formatFooterData = (data) => DataSelector(data).getAllDataByType('footer_data', 'acf');
export const formatShopData = (data) => DataSelector(data).getAllDataByType('shop_data', 'acf');
export const formatPostData = (data) => DataSelector(data).getAllDataByType('post_data');

export function checkForEmptyFields(data) {
  var valid = true;

  for (var key in data) {
    if (typeof data[key] === 'undefined' || data[key] === null || data[key] === '') {
      valid = false;
    }

    if (valid && typeof data[key] === 'object') {
      checkForEmptyFields(data[key]);
    }
  }
  return valid;
}