import styled from 'styled-components';

export const Container = styled.div`
  min-height:21.74vw;
  width: 60.31vw;
  height: 100%;
  display: flex;
  margin-left: 4.875vw;
  background: white;
  margin-top: -21.75vw;
  z-index: 1;
  flex-direction: column;
`;

export const Heading = styled.div`
  height: 12.19vw;
  background: #F3F0EB;
  display: flex;
  flex-direction: column;
`;

export const _Date = styled.div`
  height: 2.125vw;
  width: fit-content;
  padding: 0 0.9375vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 1.063vw;
  color: black;
  letter-spacing: 0.41px;
  background: white;
`;
export const Category = styled.div`
  background: #E1CFCD;
  height: 2.125vw;
  width: fit-content;
  padding: 0 0.9375vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 1.063vw;
  color: #FFFFFF;
  letter-spacing: 0.41px;
`;
export const Title = styled.div`
  font-family: BigCaslon-Regular;
  font-size: 2.938vw;
  color: #000000;
  letter-spacing: 0.65px;
  margin-top: 1.563vw;
  margin-left: 3.188vw;
`;
export const Author = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 1.063vw;
  color: #000000;
  letter-spacing: 0.41px;
  margin-top: 1.000vw;
  margin-left:3.688vw;
`;

export const PostContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2.438vw 4.938vw;
  height: 100%;
  overflow-y: scroll;
  *  {
    max-width: calc(60.31vw - (4.938vw * 2));
  }
`;