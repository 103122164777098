export function DataSelector(state) {
  var self = {
    getAllDataByType: (type, field) => {
      let slice = [];

      for (var key in state) {
        if (key.includes(`${type}`)) {
          if (!state[key].edges) {
            return state[key];
          }

          let slice_array = state[key].edges.map(({ node }) => node);

          if (!field) {
            return slice_array;
          }

          try {
            slice = slice_array[0][field];
            slice_array.forEach(({ [field]: el }, index) => {
              Object.keys(el).forEach((el_key) => {
                if (slice_array[index][field][el_key]) {
                  slice = slice_array[index];
                }
              });
            });
          } catch (e) {
            if (!slice) {
              slice = slice_array[0];
            }
          }
        }
      }
      return slice[field] ? slice[field] : {};
    },
  };
  return self;
}
