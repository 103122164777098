import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3.500vw;
`;

export const SectionTitle = styled.div`
 margin-top: 5.000vw;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.8750vw;
  color: #333333;
  letter-spacing: 1.4px;
  line-height: 26px;
  text-transform: uppercase;
  margin-bottom: 1.875vw;
`;

export const SearchBar = styled.div`
  border: 1px solid rgba(0,0,0,0.10);
  border-radius: 4px;
  width: calc(16.75vw - (0.9375vw * 2));
  height: calc(3.000vw - (0.9375vw * 2));
  display: flex;
  justify-content: space-between;
  padding: 0.9375vw;
  input {
    font-family: OpenSans-SemiBold;
    font-size: 0.8750vw;
    color: #666666;
    letter-spacing: 0;
    text-align: left;
    width: 100%;
    background-color: transparent;
  }
`;
export const CategoryListing = styled.div`
`;
export const CategoryTitle = styled.div`
  display: flex;
  font-family: OpenSans-SemiBold;
  font-size: 0.8750vw;
  color: #666666;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 0.9375vw;
  cursor: pointer;
  img {
    margin-right: 0.5625vw;
  }
`;
export const Tags = styled.div`
  width: calc(16.75vw + 0.6250vw);
  display: flex;
  flex-wrap: wrap;
`;
export const Tag = styled.div`
  border: 1px solid rgba(0,0,0,0.10);
  border-radius: 4px;
  font-family: OpenSans-SemiBold;
  font-size: 0.7500vw;
  color: #666666;
  letter-spacing: 0.12px;
  width: fit-content;
  height: calc(1.875vw - (0.9375vw * 2));
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.9375vw;
  margin-right:0.6250vw;
  margin-bottom: 0.6250vw;
  cursor: pointer;
`;
export const RelatedPosts = styled.div`
`;
export const RelatedPost = styled.div`
  width: 22.63vw;
  height: 22.63vw;
  background: #C0C4D1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.750vw; 
  font-family: sofia-pro, sans-serif; 
  font-weight: 300;
  font-size: 1.625vw;
  color: #FFFFFF;
  letter-spacing: 0.22px;
  text-align: center;
`;