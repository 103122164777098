import React, { useState } from 'react';
import {
  SectionTitle, Container, SearchBar, CategoryListing, CategoryTitle, Tags, Tag, RelatedPosts, RelatedPost,
} from './styled';
import SearchIcon from './SearchIcon.svg';
import Triangle from './Triangle.svg';
const categories = ['art', 'business', 'design', 'fashion', 'photography'];
const tags = ['cache', 'design', 'development', 'frontend', 'grunt', 'javascript', 'nginx', 'project', 'server', 'ui/ux', 'wordpress'];
const related_posts = [{ title: 'About So Bridely' }, { title: 'Instagram Grid' }, { title: 'Sign Up!' }];

function SideBar() {
  const [searchValue, setSearchValue] = useState(null);
  return (
    <Container>
      <SearchBar>
        <input
          placeholder="Search"
          onChange={({ target: { value } }) => setSearchValue(value)}
          value={searchValue} />
        <img src={SearchIcon} />
      </SearchBar>
      <SectionTitle>Categories</SectionTitle>
      <CategoryListing>
        {categories.map((title, index) => (
          <CategoryTitle key={index}>
            <img src={Triangle} />
            {title}
          </CategoryTitle>
        ))}
      </CategoryListing>
      <SectionTitle>Tag</SectionTitle>
      <Tags>
        {
          tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))
        }
      </Tags>
      <RelatedPosts>
        {
          related_posts.map(({ title }, index) => (
            <RelatedPost key={index}>{title}</RelatedPost>
          ))
        }
      </RelatedPosts>
    </Container>
  );
}

export default SideBar;
