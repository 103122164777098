import React from 'react';
import { Container } from './styled';
import Body from './Body';
import Heading from './Heading';

function Blog({ posts }) {
  return (
    <Container>
      <Heading />
      <Body posts={posts}/>
    </Container>
  );
}

export default Blog;
