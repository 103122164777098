import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 5.063vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 57.25vw;
`;

export const Previous = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.8750vw;
  color: #333333;
  letter-spacing: 1px;
  img {
    width: 1.000vw;
    height: 1.000vw;
    margin-right: 0.6250vw;
  }
`;

export const Next = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.8750vw;
  color: #333333;
  letter-spacing: 1px;
  img {
    width: 1.000vw;
    height: 1.000vw;
    margin-left: 0.6250vw;
  }
`;

export const PageNumbers = styled.div`
  margin: 0 3.438vw;
  display: flex;
  width: 12.75vw;
  justify-content: space-between;
  align-items: center; 
`;

export const Number = styled.div`
  cursor: pointer;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.8750vw;
  color: ${props => props.active ? '#35CC62' : '#333333'};
  padding: 3px;
  letter-spacing: 0.5px;
  text-align: center;
`;