import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 7.375vw;
  margin-left:11.25vw;
  margin-right: 5.375vw;
  margin-bottom: 9.000vw;
  background: #F4F5FA;
  display: flex;
`;

export const Posts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.000vw;
  width: fit-content;
  border-bottom: 2px solid rgba(0,0,0,0.10);
  padding-bottom: 7.875vw;
`;

export const Post = styled.div`
  cursor: pointer;
  width: 27.63vw;
  height: 36.94vw;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Image = styled.div`
    height: 18.44vw;
    > img {
    width: 100%;
    object-fit: fill;
    height: 100%;
  }
`;

export const Category = styled.div`
  position: absolute;
  background: rgba(0,0,0,0.30);
  border-radius: 100px;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 1.2px;
  height: 1.875vw;
  padding: 0 1vw;
  top: 1.875vw;
  left: 1.875vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;
export const Title = styled.div`
  font-family: Montserrat-Regular;
  font-size: 1.313vw;
  color: #333333;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0 2.500vw;
  margin-top: 2.188vw;
`;
export const Summary = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 1.000vw;
  color: #666666;
  letter-spacing: 0.1px;
  line-height: 26px;
  margin: 0 2.500vw;
  margin-top: 0.6250vw;
`;
export const Footer = styled.div`
  border-top: 2px solid rgba(0,0,0,0.10);
  margin: 0 2.500vw;
  display: flex;
  justify-content: flex-start;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.8750vw;
  color: #333333;
  letter-spacing: 1.4px;
  line-height: 23px;
  padding: 1.250vw 0;
  margin-top: auto;
`;
export const Name = styled.div`
  color: #333333;
`;
export const Date = styled.div`
  color: #999999;
`;