import moment from 'moment';
import React from 'react';
import {
  Container, Heading, _Date, Category, Title, Author, PostContent,
} from './styled';

function Post({
  content, title, categories, date, acf: { show_heading, author },
}) {
  return (
    <Container>
      {show_heading &&
        <Heading>
          <div style={{
            margin: '0 2.563vw', marginTop: '1.625vw', display: 'flex', justifyContent: 'space-between',
          }}>
            <Category>{categories[0].name}</Category>
            <_Date>{moment(new Date(date)).format('MMMM D, YYYY')}</_Date>
          </div>
          <Title>{title}</Title>
          <Author>{author}</Author>
        </Heading>
      }
      <PostContent dangerouslySetInnerHTML={{ __html: content }} />
    </Container>
  );
}

export default Post;
